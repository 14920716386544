import { useGetMe } from "@/entities/session";
import { getCapitalized } from "@/shared/helpers";
import {
  LeadServiceSlugToText,
  PAGINATION_DEFAULT_OPTIONS,
  StatusSlugToEnum,
  StatusToText,
} from "@/shared/helpers/const";
import {
  ContactTypeEnum,
  GetClientsFilters,
  GetLeadsPagination,
  LeadsFilters,
  LeadsSortInfo,
  RoleEnum,
  SortEnum,
  useFindManyUsersQuery,
  useGetClientsQuery,
  useGetLeadsQuery,
  useGetLeadStatusesQuery,
} from "@/shared/lib/graphql";
import { IModalContextValue, ModalContext, useQueryFilters } from "@/shared/lib/react";
import { Container, Loader, StyledNoItem, WrappedTable } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { useContext, useEffect, useMemo, useState, type FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { LeadsTabs, RequestStatus } from "@/entities/leads";
import { MockLeadsFilters } from "@/shared/helpers/mocks";
import { useManagers, useSpecialists } from "@/entities/user";
import { IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { LeadsDisableTemplate, leadsTableHeaders } from "../model/const";
import { ModalCreateLead } from "./ModalCreateLead";
import { ModalAddManagerToLead } from "./ModalAddManagerToLead";
import { ModalAddSpecialist } from "@/widgets/leads";
import { useGetCurrentTabParam } from "../model/hooks/useGetCurrentTabParam";
import { LeadSpecialist, LeadManager } from "@/features/leads";
import { useDebounce } from "use-debounce";

const StyledCommentWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const LeadsTable: FC = () => {
  const { me } = useGetMe();
  const { selectedFilters } = useQueryFilters();
  const [sort, setSort] = useState<LeadsSortInfo>({});
  const [pagination, setPagination] = useState<GetLeadsPagination>(PAGINATION_DEFAULT_OPTIONS);
  const [filter, setFilter] = useState<LeadsFilters>(selectedFilters.filter);
  const navigate = useNavigate();
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const [isUpdatingLeadRow, setIsUpdatindLeadRow] = useState({});
  const { allManagers } = useManagers();
  const { allSpecialists } = useSpecialists();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedLead, setSelectedLead] = useState<null | number>(null);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [clientFilter, setClientFilter] = useState<GetClientsFilters>();

  const [managersQuery, setManagersQuery] = useState("");
  const [specialistsQuery, setSpecialistsQuery] = useState("");
  const [debouncedManagersQuery] = useDebounce(managersQuery, 500);
  const [debouncedSpecialistQuery] = useDebounce(specialistsQuery, 500);

  const currentTabParam = useGetCurrentTabParam();

  const { data: managers } = useFindManyUsersQuery({
    sort: {
      name: SortEnum.Asc,
    },
    filter: {
      name: debouncedManagersQuery,
      role: [6], //6 - менеджер, 7 - специалист
    },
    pagination: {
      skip: 0,
      take: 10,
    },
  });
  const { data: specialists } = useFindManyUsersQuery({
    sort: {
      name: SortEnum.Asc,
    },
    filter: {
      name: debouncedSpecialistQuery,
      role: [7], //6 - менеджер, 7 - специалист
    },
    pagination: {
      skip: 0,
      take: 10,
    },
  });

  const {
    data: leads,
    refetch: updateLeads,
    isLoading,
  } = useGetLeadsQuery(
    {
      input: {
        pagination,
        sort,
        filter:
          currentTabParam.key && currentTabParam.value
            ? { ...filter, [currentTabParam.key]: currentTabParam.value }
            : filter,
      },
    },
    {
      refetchInterval: 10000,
    }
  );
  const { data: leadStatuses } = useGetLeadStatusesQuery();
  const { data: clients } = useGetClientsQuery({
    input: {
      filter: clientFilter,
    },
  });

  const getLeadInfo = leads?.getLeads?.data?.find((item) => item.id === selectedLead);

  const tablePagination = {
    page: Math.floor((leads?.getLeads?.meta?.skip || 0) / (leads?.getLeads?.meta?.take || 1)),
    count: Math.ceil((leads?.getLeads?.meta?.count || 0) / (pagination.take || 1)),
  };

  const userRole: any = me?.getMe.role?.slug;
  const canChangeManager = [RoleEnum.Director, RoleEnum.HeadOfMn].includes(userRole);

  const preparedStatuses: IDropdownItem[] = useMemo(
    () =>
      leadStatuses?.getStatuses.map((item) => ({
        value: StatusSlugToEnum[item.slug],
        name: StatusToText[item.slug],
      })) || [],
    [leadStatuses?.getStatuses]
  );

  const preparedClients: IDropdownItem[] = useMemo(
    () =>
      clients?.getClients.data?.map((client) => ({
        value: client.id,
        name: `${client.name ?? ""} ${client.surname ?? ""} ${client.patronymic ?? ""}`,
      })) || [],
    [clients?.getClients]
  );

  const preparedTableData = useMemo(
    () =>
      leads?.getLeads?.data?.map((item) => {
        let contact: string = "";
        const mainPhone = item.client?.contacts?.find(
          (contact) => contact.type === ContactTypeEnum.Phone && contact.isMain
        )?.value;
        if (mainPhone) {
          contact = mainPhone;
        } else {
          const phone = item.client?.contacts?.find(
            (contact) => contact.type === ContactTypeEnum.Phone
          )?.value;
          if (phone) {
            contact = phone;
          } else {
            const email = item.client?.contacts?.find(
              (contact) => contact.type === ContactTypeEnum.Email
            )?.value;
            if (email) {
              contact = email;
            }
          }
        }

        return {
          ...item,
          manager: (
            <LeadManager
              lead={item}
              canChangeManager={canChangeManager}
              hasManagerAppointment={
                LeadsDisableTemplate[me?.getMe.role?.slug || ""]?.managerAppointment
              }
              onLeadSelect={setSelectedLead}
              invalidateLeads={updateLeads}
            />
          ),
          specialist: (
            <LeadSpecialist
              lead={item}
              isRowLoading={isUpdatingLeadRow[item.id]}
              setIsLeadLoading={(leadId: number, isLoading: boolean) => {
                setIsUpdatindLeadRow((prev) => ({ ...prev, [leadId]: isLoading }));
              }}
              hasSpecialistAppointment={
                LeadsDisableTemplate[me?.getMe.role?.slug || ""]?.specialistAppointment
              }
              onLeadSelect={setSelectedLead}
              invalidateLeads={updateLeads}
            />
          ),
          num: (
            <Paragraph size={12} weight={500}>
              <br />№{item.num}
            </Paragraph>
          ),
          type: (
            <Paragraph size={12} color="gray-600">
              {item.lService?.slug ? LeadServiceSlugToText[item.lService?.slug] : ""}
            </Paragraph>
          ),
          date: (
            <Paragraph size={12} color="gray-600">
              {new Intl.DateTimeFormat("ru-RU", {
                dateStyle: "short",
                timeStyle: "short",
                timeZone: "Europe/Moscow",
              }).format(new Date(`${new Date(item.createdAt)} +00:00`))}
            </Paragraph>
          ),
          client: (
            <Paragraph size={12} color="gray-600">
              {item?.client ? (
                <a
                  href={`/clients/${item.client?.id}`}
                  target="_blank"
                  rel="noreferrer"
                >{`${item.client?.name} ${item.client?.surname}`}</a>
              ) : (
                ""
              )}
              <br />
              {contact ? contact : ""}
            </Paragraph>
          ),
          technic: (
            <Paragraph size={12} color="gray-600">
              {item.technics && item.technics[0]
                ? `${item.technics[0].make || ""} \\ ${item.technics[0].model || ""}`
                : "Не указаны"}
            </Paragraph>
          ),
          comment: (
            <Paragraph size={12} color="gray-600">
              <StyledCommentWrapper>
                {item.contact?.comment ? `${item.contact?.comment || ""}` : "Не указан"}
              </StyledCommentWrapper>
            </Paragraph>
          ),
          status: (
            <RequestStatus
              status={(item?.leadStatuses && item?.leadStatuses[0]?.status?.slug) || "new"}
            />
          ),
        };
      }),
    [leads?.getLeads, me?.getMe, isLoading, isUpdatingLeadRow]
  );

  const handleToggleModal = (modalName: keyof IModalContextValue, flag: boolean) => {
    setModalContext((prev) => ({ ...prev, [modalName]: flag }));
  };

  const handleSort = (headerItem, order) => {
    setSort({
      [headerItem.id]: order ? getCapitalized(order) : null,
    });
  };

  const handlePaginationClick = (value) => {
    setPagination((prevState) => ({
      ...prevState,
      skip: value * (leads?.getLeads?.meta?.take || pagination.take || 10),
    }));
  };

  useEffect(() => {
    const page = searchParams.get("page");

    if (!page) return;

    handlePaginationClick(+page - 1);
  }, [searchParams]);

  useEffect(() => {
    setPagination(PAGINATION_DEFAULT_OPTIONS);
  }, [filter]);

  useEffect(() => {
    if (!isLoading) setIsPageLoaded(true);
  }, [isLoading]);

  return (
    <>
      {!isLoading || isPageLoaded ? (
        <>
          {me?.getMe.id && (
            <LeadsTabs
              selectedTab={currentTabParam}
              profileId={me?.getMe.id}
              onTabClick={(filter) => {
                setFilter(filter);
                Object.keys(filter).forEach((key) =>
                  setSearchParams({ [`${key}[filter][ext]`]: filter[key] })
                );
              }}
            />
          )}
          <Container>
            <WrappedTable
              additionalFilter={{
                [`${currentTabParam.key}[filter][ext]`]: currentTabParam.value,
              }}
              pagination={tablePagination}
              onSort={handleSort}
              onPaginationClick={handlePaginationClick}
              onRowClick={(value) => navigate(`/leads/${value.id}`)}
              showMenu={false}
              tableTitle="Все заявки"
              headers={leadsTableHeaders}
              isLoading={isLoading}
              data={preparedTableData || []}
              search={{
                placeholder: "Поиск по заявкам",
                onSearch: (value) => setFilter((prev) => ({ ...prev, query: value })),
              }}
              onFiltersApply={(data) => {
                if (Object.keys(data).length) {
                  // значение необходимо передавать в виде массива
                  if (data.clients) {
                    data.clients = [data.clients];
                  }
                  setFilter((prevState) => ({ ...prevState, ...data }));
                } else {
                  setFilter({});
                }
              }}
              filters={MockLeadsFilters(
                {
                  options:
                    managers?.findManyUsers?.data?.map((user) => ({
                      value: user.id,
                      name: `${user.name ?? ""} ${user.surname ?? ""} ${user.patronymic ?? ""}`,
                    })) || [],
                  onSearch: (query) => setManagersQuery(query),
                },
                {
                  options:
                    specialists?.findManyUsers?.data?.map((user) => ({
                      value: user.id,
                      name: `${user.name ?? ""} ${user.surname ?? ""} ${user.patronymic ?? ""}`,
                    })) || [],
                  onSearch: (query) => setSpecialistsQuery(query),
                },
                preparedStatuses,
                {
                  options: preparedClients,
                  onSearch: setClientFilter,
                }
              )}
              onMenuItemSelected={() => ({})}
              rightBtn={() => {
                if (!LeadsDisableTemplate[me?.getMe.role?.slug || ""]?.createLead) {
                  return (
                    <Button
                      variant="secondary"
                      color="gray"
                      leftIcon={true}
                      isBlock={true}
                      icon="plus"
                      onClick={() => handleToggleModal("modalCreateRequest", true)}
                    >
                      Создать заявку
                    </Button>
                  );
                } else return <></>;
              }}
            />
          </Container>
        </>
      ) : (
        <StyledNoItem>
          <Loader size="l" />
        </StyledNoItem>
      )}
      {modalContext?.modalCreateRequest && <ModalCreateLead invalidateLeads={updateLeads} />}
      {modalContext?.modalManagerAppointment && (
        <ModalAddManagerToLead selectedLead={selectedLead} invalidateLeads={updateLeads} />
      )}
      {modalContext?.modalExpertAppointment && (
        <ModalAddSpecialist
          selectedLead={selectedLead}
          leadInfo={getLeadInfo}
          invalidateLeads={updateLeads}
        />
      )}
    </>
  );
};
