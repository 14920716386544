import React, { useState } from "react";
import { Input } from "@yamaha-admin-sb/input";
import { StyledFlexRow, StyledFlexRowItem } from "@/shared/ui/styled";
import { Filters } from "@yamaha-admin-sb/filters";
import styled from "styled-components";
import { IFilter } from "@/shared/helpers/mocks";
import { Dropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";

import { Checkbox } from "@yamaha-admin-sb/checkbox";
import "react-datepicker/dist/react-datepicker.css";
import { getFormattedDate } from "@/shared/helpers/utils";
import { Button } from "@yamaha-admin-sb/button";
import { Datepicker } from "@/shared/ui/datepicker/Datepicker";
import { TQueryFilters, useQueryFilters } from "@/shared/lib/react";
import Select from "react-dropdown-select";

interface ITableFilters {
  additionalFilter?: { [key: string]: string };
  isAdditionalClerable?: boolean;
  filters?: IFilter[];
  search?: ITableSearch | ITableSearch[];
  checkbox?: ICheckboxFilter;
  rightBtn?: () => JSX.Element;
  renderLeftElement?: () => JSX.Element;
  onFiltersApply?: (arg: { [key: string]: any }) => void;
}

export interface ICheckboxFilter {
  label: string;
  value: boolean;
  onCheckClick: (value: boolean) => void;
}

export interface ITableSearch {
  placeholder: string;
  onSearch: (value?: string) => void;
}

const StyledRight = styled.div`
  margin-left: auto;
  width: 100%;

  @media (min-width: 768px) {
    width: fit-content;
  }
`;

const StyledButton = styled.div`
  height: 44px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

const StyledDropdownWrapper = styled.div`
  input {
    background-color: transparent;
  }
`;

export const TableFilters: React.FC<ITableFilters> = (props) => {
  const {
    additionalFilter,
    search,
    filters,
    checkbox,
    isAdditionalClerable,
    rightBtn,
    onFiltersApply,
    renderLeftElement,
  } = props;
  const { selectedFilters, setQueryFilters } = useQueryFilters();
  const [form, setForm] = useState<TQueryFilters>(
    selectedFilters.filter ? { ...selectedFilters.filter } : {}
  );
  const [acceptedFilters, setAcceptedFilters] = useState(form);

  const handleFiltersChange = (
    name: string,
    value: IDropdownItem | IDropdownItem[] | string | undefined
  ) => {
    if (typeof value === "string")
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    else
      setForm((prevState) => ({
        ...prevState,
        [name]: value
          ? Array.isArray(value)
            ? value?.map((val) => val.value)
            : value.value
          : undefined,
      }));
  };

  const handleFiltersApply = () => {
    setQueryFilters(form, additionalFilter);
    if (onFiltersApply) onFiltersApply(form);
    setAcceptedFilters(form);
  };

  const handleFiltersReset = () => {
    setForm({});
    if (onFiltersApply) onFiltersApply({});
    setQueryFilters({}, !isAdditionalClerable ? additionalFilter : undefined);
    setAcceptedFilters({});
  };

  return (
    <StyledFlexRow isCenter={!!checkbox}>
      {search ? (
        Array.isArray(search) ? (
          search.map((item, index) => (
            <StyledFlexRowItem key={index} col={4}>
              <Input
                isBordered={true}
                name="search"
                withIcon={true}
                icon="search"
                isClearable={true}
                onChange={(value) => item.onSearch(value || undefined)}
                placeholder={item.placeholder}
              />
            </StyledFlexRowItem>
          ))
        ) : (
          <StyledFlexRowItem col={4}>
            <Input
              isBordered={true}
              name="search"
              withIcon={true}
              icon="search"
              isClearable={true}
              onChange={(value) => search.onSearch(value || undefined)}
              placeholder={search.placeholder}
            />
          </StyledFlexRowItem>
        )
      ) : (
        <></>
      )}
      {renderLeftElement ? (
        <StyledFlexRowItem col={2}>{renderLeftElement()}</StyledFlexRowItem>
      ) : (
        <></>
      )}
      {filters?.length ? (
        <StyledFlexRowItem col={2}>
          <Filters
            isBordered={true}
            amount={Object.keys(selectedFilters.filter).length}
            onApply={handleFiltersApply}
            onReset={handleFiltersReset}
          >
            {filters.map((filter, index) =>
              filter.type === "dropdown" ? (
                <StyledDropdownWrapper key={index}>
                  {filter.isMultiple ? (
                    <Select
                      key={index}
                      options={filter.options}
                      labelField="name"
                      searchBy="name"
                      multi={filter.isMultiple}
                      placeholder={filter.placeholder}
                      searchable={filter.searchable}
                      searchFn={
                        filter.onSearch
                          ? ({ props, state }) => {
                              filter.onSearch?.(state.search);
                              return filter.options;
                            }
                          : undefined
                      }
                      values={
                        filter.options.filter((item) => form[filter.name]?.includes(item.value)) ||
                        []
                      }
                      onChange={(values) => {
                        handleFiltersChange(
                          filter.name,
                          values?.length ? (filter.isMultiple ? values || [] : values[0]) : []
                        );
                      }}
                    />
                  ) : (
                    <Dropdown
                      key={index}
                      options={filter.options}
                      label={filter.label}
                      placeholder={filter.placeholder}
                      isFilter={false}
                      isBordered={true}
                      isMultiple={filter.isMultiple}
                      searchable={filter.searchable}
                      onSearch={filter.onSearch}
                      value={
                        filter.isMultiple
                          ? filter.options.filter((item) =>
                              form[filter.name]?.includes(item.value)
                            ) || []
                          : filter?.options?.find((item) => form[filter.name] === item.value) || []
                      }
                      onChange={(values) => {
                        handleFiltersChange(
                          filter.name,
                          values?.length ? (filter.isMultiple ? values || [] : values[0]) : []
                        );
                      }}
                    />
                  )}
                </StyledDropdownWrapper>
              ) : (
                <Datepicker
                  key={index}
                  value={form[filter.name]}
                  label={filter.label}
                  placeholder={filter.placeholder}
                  isBordered={true}
                  isBlock={true}
                  maxDate={new Date()}
                  onInputOriginal={(val) =>
                    val && handleFiltersChange(filter.name, getFormattedDate(val))
                  }
                />
              )
            )}
          </Filters>
        </StyledFlexRowItem>
      ) : (
        <></>
      )}

      {checkbox ? (
        <StyledFlexRowItem col={2}>
          <Checkbox name="checkbox" onInput={checkbox.onCheckClick}>
            {checkbox.label}
          </Checkbox>
        </StyledFlexRowItem>
      ) : (
        <></>
      )}
      {Object.keys(acceptedFilters).length ||
      (additionalFilter && Object.keys(additionalFilter).length) ? (
        <StyledFlexRowItem col={2}>
          <StyledButton>
            <Button
              variant="tertiary"
              leftIcon={false}
              rightIcon={true}
              icon="cross"
              color="gray"
              isBlock={true}
              onClick={handleFiltersReset}
            >
              Сбросить фильтры
            </Button>
          </StyledButton>
        </StyledFlexRowItem>
      ) : (
        <></>
      )}

      {rightBtn ? <StyledRight>{rightBtn()}</StyledRight> : <></>}
    </StyledFlexRow>
  );
};
