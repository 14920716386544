import {
  CostAccountingEnum,
  GearboxEnum,
  LeadResourceEnum,
  NotificationTypeEnum,
  PaymentMethodEnum,
  StatusEnum,
  PaymentTypeEnum,
  DriveTypeEnum,
  FuelTypeEnum,
} from "../lib/graphql";
import { IDropdownItem } from "@yamaha-admin-sb/dropdown";

export const gearboxOptions: IDropdownItem[] = [
  {
    name: "Механическая КПП",
    value: GearboxEnum.Manual,
  },
  {
    name: "Автоматическая КПП",
    value: GearboxEnum.Automatic,
  },
  {
    name: "Роботизированная КПП",
    value: GearboxEnum.Robotic,
  },
  {
    name: "Вариативная КПП",
    value: GearboxEnum.Cvt,
  },
];

export const driveTypeOptions: IDropdownItem[] = [
  {
    name: "Передний",
    value: DriveTypeEnum.Front,
  },
  {
    name: "Задний",
    value: DriveTypeEnum.Rear,
  },
  {
    name: "Полный",
    value: DriveTypeEnum.Full,
  },
];

export const fuelTypeOptions: IDropdownItem[] = [
  {
    name: "Бензин",
    value: FuelTypeEnum.Petrol,
  },
  {
    name: "Дизель",
    value: FuelTypeEnum.Diesel,
  },
  {
    name: "Гибрид",
    value: FuelTypeEnum.Hybrid,
  },
  {
    name: "Электро",
    value: FuelTypeEnum.Eclectro,
  },
  {
    name: "Другое",
    value: FuelTypeEnum.Other,
  },
];

export const PAGINATION_DEFAULT_OPTIONS = {
  take: 40,
  skip: 0,
};

export const requiredValidationRule = {
  required: "Это поле обязательное",
};

export const emailValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string) => {
    const myEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return myEmailRegex.test(value) || "Неверный формат почты";
  },
};

export const emailValidationWithoutRequired = {
  validate: (value: string) => {
    const myEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!value) return true;
    return myEmailRegex.test(value) || "Неверный формат почты";
  },
};

export const codeValidationRule = (length: number) => ({
  required: "Это поле обязательное",
  validate: (value: string) => {
    return value.replace(/[^\d]/g, "").length === length || "Код введен некорректно";
  },
});

export const positiveNumberPriceFormatValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string) => {
    const numberRegExp = /^\d+$/;

    const normalizedValue = value?.toString()?.replace(/ /g, "");
    const isValueNumber = numberRegExp.test(normalizedValue);
    if (parseInt(normalizedValue) <= 0) {
      return "Число должно быть больше нуля";
    }

    return isValueNumber || "Неверный числовой формат";
  },
};

export const phoneValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string) => {
    const phoneRegExp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
    return phoneRegExp.test(value) || "Неверный формат телефона";
  },
};

export const passwordValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string) => {
    const myPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/g;
    return (
      myPasswordRegex.test(value) || "8 латинских символов, 1 прописная, заглавная буквы, 1 цифра"
    );
  },
};

export const nickNameValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string) => {
    const spaceRegexp = /^\S*$/;
    return spaceRegexp.test(value) || "Никнейм не должен содержать пробелы";
  },
};

export const inputValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string | number) => {
    return !!value?.toString()?.trim() || "Поле не должно быть пустым";
  },
};

export const LeadServiceSlugToText = {
  "car-selection": "Автоподбор",
  "offsite-diagnostics": "Выездная диагностика",
};

export const LeadServiceTypeToText = {
  "one-car": "1 автомобиль",
  "day-specialist": "Специалист на день",
  "car-selection": "Автоподбор",
};

export const ResourceEnumToText: { [key in LeadResourceEnum]: string } = {
  [LeadResourceEnum.Call]: "Телефон",
  [LeadResourceEnum.Site]: "Создана в системе",
  [LeadResourceEnum.Social]: "Социальная сеть",
};

export const StatusToText = {
  new: "Новая заявка",
  prefatory: "Обрабатывается",
  "to-be-agreed": "На согласовании",
  "agreed-by-client": "Клиент заполнил договор",
  agreed: "Договор согласован",
  "payment-waiting-client": "Ожидается оплата",
  "prepayment-made": "Предоплата прошла",
  "in-progress": "В работе",
  "report-loaded": "Отчет направлен клиенту",
  "payment-made": "Оплата прошла",
  "lead-closed": "Завершена", // добавить кнопки завершить отменить внизу страницы
  successful: "Успешно завершена",
  canceled: "Заявка отменена",
  "payment-decline": "Оплата отклонена",
};

export const StatusToButtonText = {
  new: "Новая заявка",
  prefatory: "Обрабатывается",
  "to-be-agreed": "На согласовании",
  "agreed-by-client": "Клиент заполнил договор",
  agreed: "Договор согласован",
  "payment-waiting-client": "Ожидается оплата",
  "prepayment-made": "Предоплата прошла",
  "in-progress": "В работе",
  "report-loaded": "Завершить работу с отчетами",
  "payment-made": "Оплата прошла",
  "lead-closed": "Завершена", // добавить кнопки завершить отменить внизу страницы
  successful: "Успешно завершена",
  canceled: "Заявка отменена",
  "payment-decline": "Оплата отклонена",
};

export const StatusSlugToEnum = {
  new: StatusEnum.New,
  prefatory: StatusEnum.Prefatory,
  "to-be-agreed": StatusEnum.ToBeAgreed,
  agreed: StatusEnum.Agreed,
  "prepayment-made": StatusEnum.PrePayment,
  "in-progress": StatusEnum.InProgress,
  "report-loaded": StatusEnum.Report,
  "payment-made": StatusEnum.Payment,
  "lead-closed": StatusEnum.Closed,
  successful: StatusEnum.Successful,
  canceled: StatusEnum.Canceled,
  "payment-waiting-client": StatusEnum.PaymentWaitingClient,
  "payment-decline": StatusEnum.PaymentDecline,
  "agreed-by-client": StatusEnum.AgreedByClient,
};

export const PaymentTypeToText: { [key in PaymentTypeEnum]: string } = {
  [PaymentTypeEnum.Payment]: "Оплат",
  [PaymentTypeEnum.Prepayment]: "Предоплат",
};

export const PaymentMethodToText: { [key in PaymentMethodEnum]: string } = {
  [PaymentMethodEnum.Cash]: "Наличные",
  [PaymentMethodEnum.Cashless]: "Онлайн-перевод",
};

export const ExpensesTypeEnumToText: { [key in CostAccountingEnum]: string } = {
  BaseReport: "Отчет базы",
  Lift: "Подъемник",
  DealerDiagnostics: "Диагностика дилера",
  RentalPremises: "Аренда помещений",
  Taxes: "Налоги",
  BankCommission: "Комиссия банка",
  Internet: "Интернет",
  Telephony: "Телефония",
  Water: "Вода в офис",
  LegalServices: "Юридические услуги",
  CleaningPremises: "Уборка помещений",
  OfficeFood: "Еда в офис",
  Other: "Прочее",
};

export const expensesTypes: IDropdownItem[] = [
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.RentalPremises],
    value: CostAccountingEnum.RentalPremises,
  },
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.Taxes],
    value: CostAccountingEnum.Taxes,
  },
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.BankCommission],
    value: CostAccountingEnum.BankCommission,
  },
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.Internet],
    value: CostAccountingEnum.Internet,
  },
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.Telephony],
    value: CostAccountingEnum.Telephony,
  },
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.Water],
    value: CostAccountingEnum.Water,
  },
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.LegalServices],
    value: CostAccountingEnum.LegalServices,
  },
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.CleaningPremises],
    value: CostAccountingEnum.CleaningPremises,
  },
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.OfficeFood],
    value: CostAccountingEnum.OfficeFood,
  },
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.Other],
    value: CostAccountingEnum.Other,
  },
];

export const diagnosticsExpenses: IDropdownItem[] = [
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.Lift],
    value: CostAccountingEnum.Lift,
  },
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.DealerDiagnostics],
    value: CostAccountingEnum.DealerDiagnostics,
  },
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.BaseReport],
    value: CostAccountingEnum.BaseReport,
  },
  {
    name: ExpensesTypeEnumToText[CostAccountingEnum.Other],
    value: CostAccountingEnum.Other,
  },
];

export const DRIVE_UNIT_OPTIONS: IDropdownItem[] = [
  {
    name: "Передний",
    value: "front",
  },
  {
    name: "Задний",
    value: "rear",
  },
  {
    name: "Полный",
    value: "full",
  },
];

export const BODY_OPTIONS: IDropdownItem[] = [
  {
    name: "Седан",
    value: "sedan",
  },
  {
    name: "Xэтчбек 5 дв",
    value: "hatch5",
  },
  {
    name: "Xэтчбек 3 дв.",
    value: "hatch3",
  },
  {
    name: "Лифтбек",
    value: "liftback",
  },
  {
    name: "Джип 5 дв.",
    value: "jeep5",
  },
  {
    name: "Джип 3 дв.",
    value: "jeep3",
  },
  {
    name: "Универсал",
    value: "saray",
  },
  {
    name: "Минивэн",
    value: "minivan",
  },
  {
    name: "Пикап",
    value: "pickup",
  },
  {
    name: "Купе",
    value: "coupe",
  },
  {
    name: "Открытый",
    value: "open",
  },
  {
    name: "Прочее",
    value: "other",
  },
];

export const NotificationTypeToText: { [key in NotificationTypeEnum]: string } = {
  ContractAgreed: "Клиент согласовал договор по заявке",
  ContractFilled: "Клиент внес данные о себе для договора по заявке",
  LeadAssigned: "Назначена заявка",
  PaymentMade: "Клиент внес оплату по заявке",
  PaymentRefunded: "Оплата возвращена по заявке",
};
