import {
  BodyConditionEnum,
  CallStatusEnum,
  LServiceEnum,
  CreateReportInput,
  DefectEnum,
  ElectronicsCondition,
  RoleEntity,
  TireSeasonEnum,
  UserEntity,
  LiquidLevel,
  BrakeLiquidLevel,
  EPasportSend,
} from "../lib/graphql";
import { IDropdownItem } from "@yamaha-admin-sb/dropdown";

export interface IFilterBase {
  label: string;
  placeholder: string;
  name: string;
  type: "dropdown" | "datepicker";
}

export interface IFilterDropdown extends IFilterBase {
  isMultiple?: boolean;
  searchable?: boolean;
  onSearch?: (arg: string) => void;
  onChange?: (arg: IDropdownItem[]) => void;
  options: IDropdownItem[];
  type: "dropdown";
}

export interface IFilterDatepicker extends IFilterBase {
  type: "datepicker";
}

export type IFilter = IFilterDropdown | IFilterDatepicker;

const STATUSES = [
  {
    name: "Принят",
    value: CallStatusEnum.Received,
  },
  {
    name: "Пропущен",
    value: CallStatusEnum.Missed,
  },
];

export const MockDaysOfWeek = {
  0: "ПН",
  1: "ВТ",
  2: "СР",
  3: "ЧТ",
  4: "ПТ",
  5: "СБ",
  6: "ВС",
};

export const MockRussianMonth = {
  1: "января",
  2: "февраля",
  3: "марта",
  4: "апреля",
  5: "мая",
  6: "июня",
  7: "июля",
  8: "августа",
  9: "сентября",
  10: "октября",
  11: "ноября",
  12: "декабря",
};

export const MockCallsFilters = (managers: Pick<UserEntity, "id" | "name">[]): IFilter[] => {
  return [
    {
      type: "dropdown",
      label: "Статус",
      placeholder: "Выберите статус",
      name: "status",
      options: STATUSES,
    },
    {
      type: "datepicker",
      label: "Дата начала",
      placeholder: "Выберите дату начала",
      name: "startDate",
    },
    {
      type: "datepicker",
      label: "Дата конца",
      placeholder: "Выберите дату конца",
      name: "endDate",
    },
    {
      type: "dropdown",
      label: "Менеджер",
      placeholder: "Выберите менеджера",
      name: "managers",
      isMultiple: true,
      options:
        managers?.map((manager) => ({
          name: manager.name,
          value: manager.id,
        })) || [],
    },
  ];
};

export const MockUsersFilters = (roles: RoleEntity[]): IFilter[] => {
  return [
    {
      type: "dropdown",
      label: "Роль",
      placeholder: "Выберите роль",
      name: "role",
      isMultiple: true,
      options:
        roles?.map((role) => ({
          name: role.name,
          value: role.id,
        })) || [],
    },
  ];
};

export const MockReviewsFilters = (
  managers: Pick<UserEntity, "id" | "name">[],
  specialists: IDropdownItem[]
): IFilter[] => {
  return [
    {
      type: "dropdown",
      label: "Оценка",
      placeholder: "Выберите оценку",
      isMultiple: true,
      name: "values",
      options: Array.from(Array(5).keys()).map((_, i) => ({
        name: (i + 1).toString(),
        value: i + 1,
      })),
    },
    {
      type: "dropdown",
      label: "Менеджер",
      placeholder: "Выберите менеджера",
      name: "managers",
      isMultiple: true,
      options:
        managers?.map((manager) => ({
          name: manager.name,
          value: manager.id,
        })) || [],
    },
    {
      type: "dropdown",
      label: "Специалист",
      placeholder: "Выберите специалиста",
      name: "specialists",
      isMultiple: true,
      options: specialists,
    },
  ];
};

export const MockScheduleFilters = (roles: RoleEntity[], users: UserEntity[]): IFilter[] => {
  return [
    {
      type: "dropdown",
      label: "Роль",
      placeholder: "Выберите роль",
      name: "role",
      isMultiple: true,
      options:
        roles?.map((role) => ({
          name: role.name,
          value: role.id,
        })) || [],
    },
    {
      type: "dropdown",
      label: "Пользователь",
      placeholder: "Выберите пользователя",
      name: "user",
      isMultiple: true,
      options:
        users?.map((user) => ({
          name: `${user.name} ${user.surname}`,
          value: user.id,
        })) || [],
    },
  ];
};

export const MockLeadsFilters = (
  managers: {
    options: IDropdownItem[];
    onSearch: (query: string) => void;
  },
  specialists: { options: IDropdownItem[]; onSearch: (query: string) => void },
  statuses: IDropdownItem[],
  clients: {
    options: IDropdownItem[];
    onSearch: ({ query }: { query: string }) => void;
  }
): IFilter[] => {
  return [
    {
      type: "dropdown",
      searchable: true,
      onSearch: (s: string) => clients.onSearch({ query: s }),
      label: "Клиенты",
      placeholder: "Выберите клиентов",
      name: "clients",
      // isMultiple: true,
      options: clients.options,
    },
    {
      type: "dropdown",
      label: "Статус",
      placeholder: "Выберите статус",
      name: "statuses",
      isMultiple: true,
      options: statuses,
    },
    {
      type: "datepicker",
      label: "Дата начала",
      placeholder: "Выберите дату начала",
      name: "startDate",
    },
    {
      type: "datepicker",
      label: "Дата конца",
      placeholder: "Выберите дату конца",
      name: "endDate",
    },
    {
      type: "dropdown",
      label: "Тип",
      placeholder: "Выберите тип",
      name: "lService",
      isMultiple: false,
      options: [
        { name: "Автоподбор", value: LServiceEnum.CarSelection },
        {
          name: "Выездная диагностика",
          value: LServiceEnum.OffsiteDiagnostics,
        },
      ],
    },
    {
      type: "dropdown",
      label: "Менеджер",
      placeholder: "Выберите менеджера",
      name: "managers",
      isMultiple: true,
      options: managers.options,
      onSearch: (s: string) => managers.onSearch(s),
    },
    {
      type: "dropdown",
      label: "Специалист",
      placeholder: "Выберите специалиста",
      name: "specialists",
      isMultiple: true,
      options: specialists.options,
      onSearch: (s: string) => specialists.onSearch(s),
    },
  ];
};
export interface IReportField {
  label: string;
  valueField: keyof CreateReportInput;
  type: "radio" | "checkbox" | "input" | "text";
  required?: boolean;
}

export interface IReportFieldRadio extends IReportField {
  type: "radio";
  values: {
    label: string;
    value: string | boolean;
  }[];
}

export interface IReportFieldCheckbox extends IReportField {
  type: "checkbox";
  values: {
    label: string;
    value: string;
  }[];
}

export interface IReportFieldInput extends IReportField {
  type: "input" | "text";
  variant?: "text" | "number";
  placeholder?: string;
  measurement?: string;
  splitValue?: boolean;
}

export type TReportField = IReportFieldRadio | IReportFieldCheckbox | IReportFieldInput;

export const MockReportCreateStepFirst: IReportFieldInput[][] = [
  [
    // Марка и модель добавлены в смаой форме с данными с бека
    // {
    //   label: "Марка",
    //   placeholder: "Укажите марку",
    //   type: "input",
    //   valueField: "mark",
    // },
    // {
    //   label: "Модель",
    //   type: "input",
    //   valueField: "model",
    // },
    {
      label: "VIN",
      type: "input",
      variant: "text",
      valueField: "vin",
      required: true,
    },
    {
      label: "№ДВС",
      type: "input",
      variant: "text",
      valueField: "engine",
    },
    {
      label: "Мощность ДВС, л.с.",
      type: "input",
      variant: "number",
      valueField: "enginePower",
      placeholder: "Укажите мощность двс в л.с.",
    },
    {
      label: "Год выпуска",
      type: "input",
      variant: "number",
      valueField: "manufactureYear",
      required: true,
    },
    {
      label: "Объем ДВС",
      type: "input",
      variant: "number",
      valueField: "engineVolume",
      required: true,
    },
  ],
  [
    {
      label: "Заявленный пробег",
      type: "input",
      variant: "number",
      splitValue: true,
      measurement: "км",
      valueField: "mileage",
      required: true,
    },
    {
      label: "Заявленная стоимость",
      type: "input",
      measurement: "₽",
      splitValue: true,
      variant: "number",
      placeholder: "Укажите заявленную стоимость",
      valueField: "declaredPrice",
      required: true,
    },
    {
      label: "Итоговая стоимость",
      type: "input",
      splitValue: true,
      measurement: "₽",
      variant: "number",
      placeholder: "Укажите итоговую стоимость",
      valueField: "finalPrice",
      required: true,
    },
  ],
];

export const MockReportCreateSteps: {
  title: string;
  fields: TReportField[];
  withDisclaimer?: boolean;
}[] = [
  {
    title: "Рекомендация",
    withDisclaimer: true,
    fields: [
      {
        valueField: "isRecommended",
        label: "Автопрагмат рекомендует",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
    ],
  },
  {
    title: "Юридическая чистота",
    fields: [
      {
        valueField: "hasOriginalPassport",
        label: "ПТС оригинал",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
      {
        valueField: "hasEPassport",
        label: "ПТС электронный",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
      {
        valueField: "sendEPassport",
        label: "",
        type: "radio",
        values: [
          {
            label: "Передан",
            value: EPasportSend.Send,
          },
          {
            label: "Не передан",
            value: EPasportSend.NotSend,
          },
        ],
      },
      {
        valueField: "owners",
        label: "Количество владельцев",
        type: "input",
        variant: "number",
      },
      {
        valueField: "freeFields",
        label: "Количество свободных полей",
        type: "input",
        variant: "number",
      },
      {
        valueField: "serviceConfirmation",
        label: "Подтверждение обслуживания",
        type: "input",
      },
      {
        valueField: "hasRegRestrictions",
        label: "Ограничения на рег. Действия",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
      {
        valueField: "hasAccidents",
        label: "Факты ДТП",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
      {
        valueField: "inFBS",
        label: "База ФССП",
        type: "radio",
        values: [
          {
            label: "Числится",
            value: true,
          },
          {
            label: "Не числится",
            value: false,
          },
        ],
      },
      {
        valueField: "inPledges",
        label: "Реестр залогов",
        type: "radio",
        values: [
          {
            label: "Числится",
            value: true,
          },
          {
            label: "Не числится",
            value: false,
          },
        ],
      },
      {
        valueField: "report",
        label: "Отчет",
        type: "input",
        placeholder: "Вставьте ссылку",
      },
    ],
  },
  {
    title: "Кузов",
    fields: [
      {
        valueField: "bodyCondition",
        label: "Состояние кузова",
        type: "radio",
        values: [
          {
            label: "Чистый",
            value: BodyConditionEnum.Clear,
          },
          {
            label: "Грязный",
            value: BodyConditionEnum.Dirty,
          },
        ],
      },
      {
        valueField: "roofPaintThickness",
        label: "Толщина ЛКП крыши",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП крыши",
        measurement: "мкм",
      },
      {
        valueField: "roofDefects",
        label: "Дефекты на крыше",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "hoodPaintThickness",
        label: "Толщина ЛКП капота",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП капота",
        measurement: "мкм",
      },
      {
        valueField: "hoodDefects",
        label: "Дефекты на капоте",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "frontLeftFenderPaintThickness",
        label: "Толщина ЛКП переднего левого крыла",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП переднего левого крыла",
        measurement: "мкм",
      },
      {
        valueField: "frontLeftFenderDefects",
        label: "Дефекты на переднем левом крыле",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "frontLeftDoorPaintThickness",
        label: "Толщина ЛКП передней левой двери",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП передней левой двери",
        measurement: "мкм",
      },
      {
        valueField: "frontLeftDoorDefects",
        label: "Дефекты на передней левой двери",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "rearLeftDoorPaintThickness",
        label: "Толщина ЛКП задней левой двери",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП задней левой двери",
        measurement: "мкм",
      },
      {
        valueField: "rearLeftDoorDefects",
        label: "Дефекты на задней левой двери",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "rearLeftFenderPaintThickness",
        label: "Толщина ЛКП заднего левого крыла",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП заднего левого крыла",
        measurement: "мкм",
      },
      {
        valueField: "rearLeftFenderDefects",
        label: "Дефекты на заднем левом крыле",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "leftThresholdPaintThickness",
        label: "Толщина ЛКП левого порога",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП левого порога",
        measurement: "мкм",
      },
      {
        valueField: "leftThresholdDefects",
        label: "Дефекты на левом пороге",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "trunkLidPaintThickness",
        label: "Толщина ЛКП крышки багажника",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП крышки багажника",
        measurement: "мкм",
      },
      {
        valueField: "trunkLidDefects",
        label: "Дефекты на крышке багажника",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "frontRightFenderPaintThickness",
        label: "Толщина ЛКП переднего правого крыла",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП переднего правого крыла",
        measurement: "мкм",
      },
      {
        valueField: "frontRightFenderDefects",
        label: "Дефекты на переднем правом крыле",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "frontRightDoorPaintThickness",
        label: "Толщина ЛКП передней правой двери",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП передней правой двери",
        measurement: "мкм",
      },
      {
        valueField: "frontRightDoorDefects",
        label: "Дефекты на передней правой двери",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "rearRightDoorPaintThickness",
        label: "Толщина ЛКП задней правой двери",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП задней правой двери",
        measurement: "мкм",
      },
      {
        valueField: "rearRightDoorDefects",
        label: "Дефекты на задней правой двери",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "rearRightFenderPaintThickness",
        label: "Толщина ЛКП заднего правого крыла",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП заднего правого крыла",
        measurement: "мкм",
      },
      {
        valueField: "rearRightFenderDefects",
        label: "Дефекты на заднем правом крыле",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "rightThresholdPaintThickness",
        label: "Толщина ЛКП правого порога",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП правого порога",
        measurement: "мкм",
      },
      {
        valueField: "rightThresholdDefects",
        label: "Дефекты на правом пороге",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "aperturePaintThickness",
        label: "Толщина ЛКП проемов",
        type: "input",
        variant: "number",
        placeholder: "Укажите толщину ЛКП проемов",
        measurement: "мкм",
      },
      {
        valueField: "apertureDefects",
        label: "Дефекты в проемах",
        type: "checkbox",
        values: [
          {
            label: "Скол",
            value: DefectEnum.Chip,
          },
          {
            label: "Вмятина",
            value: DefectEnum.Dent,
          },
          {
            label: "Царапина",
            value: DefectEnum.Scratch,
          },
          {
            label: "Притертость",
            value: DefectEnum.Wear,
          },
        ],
      },
      {
        valueField: "interiorCondition",
        label: "Состояние салона",
        type: "text",
      },
      {
        valueField: "carBodyRemarks",
        label: "Замечания",
        type: "text",
      },
    ],
  },
  {
    title: "Стекла / фары",
    fields: [
      {
        valueField: "isOriginalWindshield",
        label: "Лобовое стекло оригинал",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
      {
        valueField: "windshieldManufactureYear",
        type: "input",
        variant: "number",
        label: "Год выпуска лобового стекла",
      },
      {
        valueField: "windshieldCondition",
        type: "text",
        label: "Состояние лобового стекла",
      },
      {
        valueField: "isOriginalSideGlasses",
        label: "Боковые стекла оригинал",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
      {
        valueField: "sideGlassesManufactureYear",
        type: "input",
        variant: "number",
        label: "Год выпуска боковых стекол",
      },
      {
        valueField: "sideGlassesCondition",
        type: "text",
        label: "Состояние боковых стекол",
      },
      {
        valueField: "isOriginalRearGlass",
        label: "Зеднее стекло оригинал",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
      {
        valueField: "rearGlassManufactureYear",
        type: "input",
        variant: "number",
        label: "Год выпуска заднего стекла",
      },
      {
        valueField: "rearGlassCondition",
        type: "text",
        label: "Состояние заднего стекла",
      },
      {
        valueField: "isOriginalFrontRightHeadlight",
        label: "Передняя правая фара оригинал",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
      {
        valueField: "frontRightHeadlightManufactureYear",
        type: "input",
        variant: "number",
        label: "Год выпуска передней правой фары",
      },
      {
        valueField: "frontRightHeadlightCondition",
        type: "text",
        label: "Состояние передней правой фары",
      },
      {
        valueField: "isOriginalFrontLeftHeadlight",
        label: "Передняя левая фара оригинал",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
      {
        valueField: "frontLeftHeadlightManufactureYear",
        type: "input",
        variant: "number",
        label: "Год выпуска передней левой фары",
      },
      {
        valueField: "frontLeftHeadlightCondition",
        type: "text",
        label: "Состояние передней левой фары",
      },
      {
        valueField: "isOriginalRearLeftHeadlight",
        label: "Задний левый фонарь оригинал",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
      {
        valueField: "rearLeftHeadlightManufactureYear",
        type: "input",
        variant: "number",
        label: "Год выпуска заднего левого фонаря",
      },
      {
        valueField: "rearLeftHeadlightCondition",
        type: "text",
        label: "Состояние заднего левого фонаря",
      },
      {
        valueField: "isOriginalRearRightHeadlight",
        label: "Задний правый фонарь оригинал",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
      {
        valueField: "rearRightHeadlightManufactureYear",
        type: "input",
        variant: "number",
        label: "Год выпуска заднего правого фонаря",
      },
      {
        valueField: "rearRightHeadlightCondition",
        type: "text",
        label: "Состояние заднего правого фонаря",
      },
      {
        valueField: "glassAndHeadlightsRemarks",
        type: "text",
        label: "Замечания",
      },
    ],
  },
  {
    title: "Техническая часть",
    fields: [
      {
        valueField: "engineOilLevel",
        label: "Масло в ДВС",
        type: "checkbox",
        values: [
          {
            label: "Норма",
            value: LiquidLevel.Norm,
          },
          {
            label: "Не норма",
            value: LiquidLevel.NotNorm,
          },
          {
            label: "Минимум",
            value: LiquidLevel.Min,
          },
          {
            label: "Максимум",
            value: LiquidLevel.Max,
          },
        ],
      },
      {
        valueField: "brakeFluidLevel",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: BrakeLiquidLevel.Norm,
          },
          {
            label: "Не норма",
            value: BrakeLiquidLevel.NotNorm,
          },
        ],
        label: "Уровень тормозной жидкости",
      },
      {
        valueField: "coolantLevel",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: LiquidLevel.Norm,
          },
          {
            label: "Не норма",
            value: LiquidLevel.NotNorm,
          },
          {
            label: "Минимум",
            value: LiquidLevel.Min,
          },
          {
            label: "Максимум",
            value: LiquidLevel.Max,
          },
        ],
        label: "Уровень охлаждающей жидкости",
      },
      {
        valueField: "AHSLevel",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: LiquidLevel.Norm,
          },
          {
            label: "Не норма",
            value: LiquidLevel.NotNorm,
          },
          {
            label: "Минимум",
            value: LiquidLevel.Min,
          },
          {
            label: "Максимум",
            value: LiquidLevel.Max,
          },
        ],
        label: "Уровень масла ГУР",
      },
      {
        valueField: "driveBeltCondition",
        type: "text",
        label: "Состояние приводного ремня",
      },
      {
        valueField: "engineLeak",
        type: "text",
        label: "Течь в двигателе",
      },
      {
        valueField: "hasEngineExtraSounds",
        label: "Посторонние звуки в двигателе",
        type: "radio",
        values: [
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ],
      },
      {
        valueField: "technicalRecommendations",
        type: "text",
        label: "Рекомендации",
      },
    ],
  },
  {
    title: "Тормоза",
    fields: [
      {
        valueField: "frontPadWear",
        type: "input",
        label: "Износ передних колодок",
        placeholder: "Укажите уровень износа передних колодок",
        variant: "number",
        measurement: "%",
      },
      {
        valueField: "frontDiscWear",
        type: "input",
        label: "Износ передних дисков",
        variant: "number",
        placeholder: "Укажите уровень износа передних дисков",
        measurement: "%",
      },
      {
        valueField: "rearPadWear",
        type: "input",
        label: "Износ задних колодок",
        variant: "number",
        placeholder: "Укажите уровень износа задних колодок",
        measurement: "%",
      },
      {
        valueField: "rearDiscWear",
        type: "input",
        label: "Износ задних дисков",
        variant: "number",
        placeholder: "Укажите уровень износа задних дисков",
        measurement: "%",
      },
      {
        valueField: "brakesRecommendations",
        type: "text",
        label: "Рекомендации",
      },
    ],
  },
  {
    title: "Резина",
    fields: [
      {
        valueField: "tireModel",
        type: "input",
        variant: "text",
        label: "Модель шин",
      },
      {
        valueField: "tireSeason",
        label: "Сезон",
        type: "radio",
        values: [
          {
            label: "Зима",
            value: TireSeasonEnum.Winter,
          },
          {
            label: "Лето",
            value: TireSeasonEnum.Summer,
          },
          {
            label: "Всесезонные",
            value: TireSeasonEnum.AllSeason,
          },
        ],
      },
      {
        valueField: "tireManufactureYear",
        type: "input",
        variant: "number",
        label: "Год выпуска шин",
      },
      {
        valueField: "tireTread",
        type: "input",
        variant: "number",
        label: "Остаток протектора",
        measurement: "%",
      },
      { valueField: "tireRemark", label: "Замечания", type: "text" },
    ],
  },
  {
    title: "Электроника",
    fields: [
      {
        valueField: "powerWindows",
        label: "Стеклоподъёмники",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "mirrorAdjustment",
        label: "Регулировка зеркал",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "heatedMirrors",
        label: "Обогрев зеркал",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "foldingMirrors",
        label: "Складывание зеркал",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "seatsCondition",
        label: "Состояние сидений",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "seatsAdjustment",
        label: "Регулировка сидений",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "seatsHeating",
        label: "Обогрев сидений",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "steeringWheelCondition",
        label: "Состояние руля",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "steeringWheelAdjustment",
        label: "Регулировка руля",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "steeringWheelHeating",
        label: "Обогрев руля",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "airConditioner",
        label: "Работа кондиционера",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "multimedia",
        label: "Работа мультимедиа",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "speakers",
        label: "Работа колонок",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "antenna",
        label: "Работа антенны",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "headlights",
        label: "Работа фар",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "lamps",
        label: "Работа фонарей",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "backlight",
        label: "Работа подсветки",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "windshieldWipers",
        label: "Работа стеклоочистителей",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "headlightsWasher",
        label: "Работа омывателя фар",
        type: "radio",
        values: [
          {
            label: "Норма",
            value: ElectronicsCondition.Norm,
          },
          {
            label: "Не норма",
            value: ElectronicsCondition.NotNorm,
          },
          {
            label: "Не проверить",
            value: ElectronicsCondition.DoNotCheck,
          },
        ],
      },
      {
        valueField: "computerDiagnostics",
        label: "Результат компьютерной диагностики",
        type: "text",
      },
      {
        valueField: "electronicsRecommendations",
        label: "Рекомендации",
        type: "text",
      },
    ],
  },
  {
    title: "Тестовая поездка",
    fields: [
      {
        valueField: "testRideRemarks",
        label: "Замечания",
        type: "text",
      },
    ],
  },
  {
    title: "Подъемник",
    fields: [
      {
        valueField: "liftRemarks",
        label: "Замечания",
        type: "text",
      },
    ],
  },
  {
    title: "Доп. диагностика",
    fields: [
      {
        valueField: "endoscopeRemarks",
        label: "Замечания",
        type: "text",
      },
    ],
  },
  {
    title: "Дополнительное оборудование",
    fields: [
      {
        valueField: "optionalEquipmentRemarks",
        label: "Дополнительное оборудование",
        type: "text",
        placeholder: "Перечислите дополнительное оборудование",
      },
    ],
  },
];

export const MockExpensesFilters = (users?: Pick<UserEntity, "id" | "name">[]): IFilter[] => {
  return [
    // {
    //   type: "dropdown",
    //   label: "Пользователь",
    //   placeholder: "Выберите пользователя",
    //   name: "userId",
    //   isMultiple: false,
    //   options: users,
    // },
    // {
    //   type: "dropdown",
    //   label: "Тип",
    //   placeholder: "Выберите тип",
    //   name: "type",
    //   isMultiple: false,
    //   options: expensesTypes,
    // },
    {
      type: "datepicker",
      label: "Дата начала",
      placeholder: "Выберите дату начала",
      name: "startDate",
    },
    {
      type: "datepicker",
      label: "Дата конца",
      placeholder: "Выберите дату конца",
      name: "endDate",
    },
  ];
};

// export const MockReviewsFilters = (): IFilter[] => {
//   return [
//     {
//       type: "dropdown",
//       label: "Оценка",
//       placeholder: "Выберите оценку",
//       name: "value",
//       options: Array.from(Array(5).keys()).map((_, i) => ({
//         name: (i + 1).toString(),
//         value: i + 1,
//       })),
//     },
//   ];
// };
