import { DocumentPreview } from "@/entities/document";
import { ImageGallery, Loader } from "@/shared/ui";
import {
  LeadReportOutput,
  useDeleteAttachmentsMutation,
  useGetAttachmentsQuery,
} from "@/shared/lib/graphql";
import { media, colors } from "@/shared/helpers";
import { useAddNotification } from "@/shared/lib/react";
import { useSendPhotos } from "../../model/useSendPhotos";
import { Button } from "@yamaha-admin-sb/button";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { TextButton } from "@yamaha-admin-sb/text-button";
import moment from "moment";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { getFile } from "@/shared/api";
import { getDocument } from "../../model/getDocument";

interface IReportPreview {
  leadId: number;
  report: LeadReportOutput;
  isUpdationdReport: boolean;
  isDisabled: boolean;
  enableImageLoad: boolean;
  isOffsiteDiagnostics: boolean;
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  onView?: (id: number) => void;
  onDownload?: (id: number) => void;
  handleSendReportToClient: (report: LeadReportOutput) => void;
}

const StyledImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
`;

const StyledImagePreview = styled.div`
  padding: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 94px;
  height: 94px;
  border-radius: 12px;
  img {
    display: block;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;

    transform: translate(-50%, -50%);
  }
  border: 2px solid var(--color-white);
  &:hover {
    border: 2px solid var(--color-blue-200);
  }
`;

const StyledDelete = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const WrappedLoader = styled.div`
  margin: 8px 0;
`;

const WrappedButton = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;

  gap: 16px;

  ${media.desktop} {
    flex-direction: row;
    align-items: center;
  }
`;

const StyledList = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;

  flex-direction: column;
  ${media.desktop} {
    gap: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const WrappedReportPreview = styled.div`
  border-bottom: 1px solid ${colors.gray200};
  &:not(:last-child) {
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
`;

const WrappedInfo = styled.div`
  margin-top: 24px;
`;

export const ReportPreview: React.FC<IReportPreview> = (props) => {
  const {
    report,
    leadId,
    isUpdationdReport,
    isDisabled,
    enableImageLoad,
    isOffsiteDiagnostics,
    onDelete,
    onEdit,
    onView,
    onDownload,
    handleSendReportToClient,
  } = props;
  const [selectedReport, setSelectedReport] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [photoPublicLinks, setPhotoPublicLinks] = useState<{} | undefined>(undefined);
  const [showPreview, setShowPreview] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const inputFile: RefObject<HTMLInputElement> = useRef(null);
  const notify = useAddNotification();

  const { uploadFiles, isImagesUploading, isLinkSending } = useSendPhotos({
    leadId,
  });
  const {
    data: images,
    isLoading: isImagesLoading,
    refetch: updateImages,
  } = useGetAttachmentsQuery({
    leadId,
  });
  const { mutate: deleteImage } = useDeleteAttachmentsMutation();

  const handleDeleteAttahcment = ({ ids }: { ids: number[] }) => {
    deleteImage(
      { leadId, input: { ids } },
      {
        onSuccess: () => updateImages(),
        onError: (error) => {
          notify((error as ErrorEvent)?.message, "error");
        },
      }
    );
  };

  useEffect(() => {
    const processImages = async () => {
      const getPublicLinks = await images?.getAttachments.reduce(async (accPromise, item) => {
        const acc = await accPromise;
        acc[item.id] = item.filePath ? await getDocument({ key: item.filePath }) : item.fileUrl;
        return acc;
      }, Promise.resolve({}));
      setPhotoPublicLinks(getPublicLinks);
    };

    processImages();
  }, [images]);

  const preparedImagesList = useMemo(
    () =>
      images?.getAttachments.reduce(
        (acc, item) => ({
          ...acc,
          [item.reportId]: acc[item.reportId] ?
            [...acc[item.reportId], {
              ...item,
              fileUrl: item.fileUrl ? photoPublicLinks?.[item.id] : item.fileUrl,
            }] :
            [{
              ...item,
              fileUrl: item.fileUrl ? photoPublicLinks?.[item.id] : item.fileUrl,
            }],
        }),
        {}
      ),
    [photoPublicLinks]
  );


  return (
    <WrappedReportPreview>
      <StyledList>
        <DocumentPreview
          key={report.id}
          name={report.fileName || "Отчет"}
          size={`${report.fileSize?.toString()} МБ` || ""}
          onDelete={() => onDelete && onDelete(report.id)}
          onEdit={() => onEdit && onEdit(report.id)}
          onView={() => onView && onView(report.id)}
          onDownload={() => onDownload && onDownload(report.id)}
        />
        <Paragraph size={12} color="gray-600">
          От {moment(report.createdAt).format("DD.MM.YYYY")}
        </Paragraph>
        <WrappedButton>
          {!report.isPublic ? (
            <Button
              isDisabled={
                isUpdationdReport ||
                (isOffsiteDiagnostics &&
                  preparedImagesList &&
                  !preparedImagesList[report?.id]?.length)
              }
              isLoading={isUpdationdReport}
              color="gray"
              variant="secondary"
              onClick={() => handleSendReportToClient(report)}
            >
              Отправить клиенту
            </Button>
          ) : (
            <></>
          )}
          <Button
            isLoading={isImagesUploading || isLinkSending}
            leftIcon={true}
            icon="upload"
            isBlock={false}
            isDisabled={isDisabled || !enableImageLoad}
            onClick={() => {
              setSelectedReport(report.id);
              inputFile.current?.click();
            }}
          >
            Загрузить фото
          </Button>
          <input
            type="file"
            accept="image/*"
            ref={inputFile}
            multiple={true}
            style={{ display: "none" }}
            onChange={(event) => {
              const eventCopy = { ...event };
              uploadFiles({
                reportId: selectedReport,
                event: eventCopy,
                onAllFilesUpload: () => {
                  updateImages();
                  notify("Все изображения загружены", "success");
                },
              });
            }}
          />
        </WrappedButton>
      </StyledList>
      {!isImagesLoading ? (
        preparedImagesList && preparedImagesList[report?.id]?.length ? (
          <>
            <StyledImages>
              {preparedImagesList[report?.id].map((item, index) =>
                (index <= 5 && !showAll) || showAll ? (
                  <StyledImagePreview
                    key={item.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowPreview(true);
                      setStartIndex(index);
                    }}
                  >
                    <img alt={item.fileName || ""} src={item.fileUrl || ""} />
                    <StyledDelete
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteAttahcment({ ids: [item.id] });
                      }}
                    >
                      <IconButton icon="cross" size="s" color="gray" />
                    </StyledDelete>
                  </StyledImagePreview>
                ) : (
                  <></>
                )
              )}
            </StyledImages>
            {preparedImagesList[report.id].length > 5 ? (
              <TextButton
                rightIcon={true}
                leftIcon={false}
                icon={showAll ? "chevron-up" : "chevron-down"}
                onClick={() => setShowAll((prev) => !prev)}
              >
                {showAll ? "Скрыть" : "Показать еще"}
              </TextButton>
            ) : (
              <></>
            )}
            {showPreview ? (
              <ImageGallery
                gallery={preparedImagesList[report.id]}
                startIndex={startIndex}
                onClose={() => setShowPreview(false)}
              />
            ) : (
              <></>
            )}
          </>
        ) : isOffsiteDiagnostics ? (
          <WrappedInfo>
            <Paragraph size={12} color="gray-600">
              Необходимо загрузить фото для отправки отчета клиенту
            </Paragraph>
          </WrappedInfo>
        ) : (
          <></>
        )
      ) : (
        <WrappedLoader>
          <Loader size="m" />
        </WrappedLoader>
      )}
    </WrappedReportPreview>
  );
};
