import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { MockReportCreateStepFirst } from "@/shared/helpers/mocks";
import { IInput, Input } from "@yamaha-admin-sb/input";
import {
  driveTypeOptions,
  fuelTypeOptions,
  gearboxOptions,
  inputValidationRule,
  requiredValidationRule,
} from "@/shared/helpers/const";
import { getPlaceholderFromLabel, toFormatPrice } from "@/shared/helpers/utils";
import { ModalBody, ModalFooter, Controller } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { useGetVehicleMarksQuery, useGetVehicleModelsQuery } from "@/shared/lib/graphql";
import { ICreateReportInputRequired } from "../../../model/interfaces";

interface IModalCreateReportFirstStep {
  data: ICreateReportInputRequired;
  onCancel: () => void;
  onSubmit: () => void;
  onChange: (
    name: keyof ICreateReportInputRequired,
    value: string | number,
    variant?: "text" | "number"
  ) => void;
}

const StyledModalCreateReportFirstStep = styled.form``;

const ModalCreateReportFirstStep: React.VFC<IModalCreateReportFirstStep> = (props) => {
  const { data, onChange, onCancel, onSubmit } = props;
  const [selectedMarks, setSelectedMarks] = useState<IDropdownItem[]>([]);
  const [selectedFilters, setSelectedFilters] = useState({
    model: {
      name: "",
      id: 0,
    },
    mark: {
      name: "",
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur", defaultValues: data });

  const { data: models, isLoading: isModelsLoading } = useGetVehicleModelsQuery(
    {
      filter: selectedMarks[0]?.id
        ? selectedFilters.model.name
          ? {
              markId: selectedMarks[0]?.id.toString(),
              name: selectedFilters.model.name || null,
            }
          : {
              markId: selectedMarks[0]?.id.toString(),
            }
        : {},
    },
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!selectedMarks[0]?.id,
    }
  );
  const { data: marks, isLoading: isMarksLoading } = useGetVehicleMarksQuery({
    filter: selectedFilters?.mark?.name
      ? {
          name: selectedFilters.mark.name,
        }
      : {},
  });

  useEffect(() => {
    const prevMarks = marks?.getVehicleMarks.find((item) => item.name === data.mark);
    setValue("mark", data.mark);
    prevMarks && setSelectedMarks([prevMarks]);
  }, [data.mark, marks]);

  useEffect(() => {
    Object.keys(data).forEach((item) =>
      setValue(item as keyof ICreateReportInputRequired, data[item])
    );
  }, [data]);

  return (
    <StyledModalCreateReportFirstStep onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <div className="space-20">
          <Controller<IDropdown>
            // portal={document.querySelector("body") as HTMLElement}
            name="mark"
            label="Марка *"
            searchable={true}
            onSearch={(value) => setSelectedFilters((prev) => ({ ...prev, mark: { name: value } }))}
            placeholder="Выберите марку"
            control={control}
            error={errors?.mark?.message}
            options={marks?.getVehicleMarks || []}
            isDisabled={isMarksLoading}
            isBordered={true}
            value={
              [marks?.getVehicleMarks?.find((item) => item.name === data.mark) || []].flat() || []
            }
            rules={requiredValidationRule}
            onChange={(value: IDropdownItem[]) => {
              if (value[0]?.name) {
                onChange("mark", value[0].name, "text");
                // onChange("model", "");
                setSelectedMarks(value);
              }
            }}
            component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
          />
        </div>
        <div className="space-20">
          <Controller<IDropdown>
            // portal={document.querySelector("body") as HTMLElement}
            name="model"
            label="Модель *"
            placeholder="Выберите модель"
            control={control}
            options={models?.getVehicleModels || []}
            isBordered={true}
            value={
              [models?.getVehicleModels?.find((item) => item.name === data.model) || []].flat() ||
              []
            }
            isMultiple={false}
            isDisabled={!data.mark || isMarksLoading || isModelsLoading}
            searchable={true}
            onSearch={(value) =>
              setSelectedFilters((prev) => ({
                ...prev,
                model: { ...prev.model, name: value },
              }))
            }
            error={errors.model?.message}
            rules={requiredValidationRule}
            onChange={(value: IDropdownItem[]) => {
              value[0]?.name && onChange("model", value[0].name, "text");
            }}
            component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
          />
        </div>
        {MockReportCreateStepFirst[0].map((input) => {
          if (input.valueField === "enginePower")
            return (
              <Fragment key={input.valueField}>
                <div className="space-20" key={input.valueField}>
                  <Controller<IInput>
                    name={input.valueField}
                    label={`${input.label} ${input.required ? "*" : ""}`}
                    placeholder={input.placeholder || getPlaceholderFromLabel(input.label)}
                    type={input.variant || "text"}
                    control={control}
                    suffix={input.measurement}
                    error={errors?.[input.valueField]?.message}
                    value={data[input.valueField]}
                    isBordered={true}
                    rules={input.required ? inputValidationRule : null}
                    onChange={(value) =>
                      onChange(
                        input.valueField as keyof ICreateReportInputRequired,
                        input.variant === "text" ? String(value) : value,
                        input.variant
                      )
                    }
                    component={(inputProps: IInput) => <Input {...inputProps} />}
                  />
                </div>
                <div className="space-20">
                  <Controller<IDropdown>
                    name="fuelType"
                    label="Тип топлива *"
                    placeholder="Выберите тип топлива"
                    control={control}
                    error={errors?.fuelType?.message}
                    options={fuelTypeOptions}
                    isBordered={true}
                    value={
                      [
                        fuelTypeOptions?.find((item) => item.value === data.fuelType) || [],
                      ].flat() || []
                    }
                    rules={requiredValidationRule}
                    onChange={(value: IDropdownItem[]) =>
                      value[0]?.value && onChange("fuelType", value[0].value)
                    }
                    component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
                  />
                </div>
              </Fragment>
            );
          return (
            <div className="space-20" key={input.valueField}>
              <Controller<IInput>
                name={input.valueField}
                label={`${input.label} ${input.required ? "*" : ""}`}
                placeholder={input.placeholder || getPlaceholderFromLabel(input.label)}
                type={input.variant || "text"}
                control={control}
                suffix={input.measurement}
                error={errors?.[input.valueField]?.message}
                value={data[input.valueField]}
                isBordered={true}
                rules={input.required ? inputValidationRule : null}
                onChange={(value) =>
                  onChange(
                    input.valueField as keyof ICreateReportInputRequired,
                    input.variant === "text" ? String(value) : value,
                    input.variant
                  )
                }
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
          );
        })}
        <div className="space-20">
          <Controller<IDropdown>
            name="gearbox"
            label="Тип КПП *"
            placeholder="Выберите тип КПП"
            control={control}
            error={errors?.gearbox?.message}
            options={gearboxOptions}
            isBordered={true}
            value={[gearboxOptions?.find((item) => item.value === data.gearbox) || []].flat() || []}
            rules={requiredValidationRule}
            onChange={(value: IDropdownItem[]) =>
              value[0]?.value && onChange("gearbox", value[0].value)
            }
            component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
          />
        </div>
        <div className="space-20">
          <Controller<IDropdown>
            name="driveType"
            label="Тип привода *"
            placeholder="Выберите тип привода"
            control={control}
            error={errors?.driveType?.message}
            options={driveTypeOptions}
            isBordered={true}
            value={
              [driveTypeOptions?.find((item) => item.value === data.driveType) || []].flat() || []
            }
            rules={requiredValidationRule}
            onChange={(value: IDropdownItem[]) =>
              value[0]?.value && onChange("driveType", value[0].value)
            }
            component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
          />
        </div>
        {MockReportCreateStepFirst[1].map((input) => (
          <div className="space-20" key={input.valueField}>
            <Controller<IInput>
              name={input.valueField}
              label={`${input.label} *`}
              placeholder={input.placeholder || getPlaceholderFromLabel(input.label)}
              control={control}
              type={(!input.splitValue && input?.variant) || "text"}
              error={errors?.[input.valueField]?.message}
              value={
                input.splitValue
                  ? data[input.valueField] && !isNaN(+data[input.valueField])
                    ? toFormatPrice(+data[input.valueField])
                    : ""
                  : data[input.valueField]
              }
              isBordered={true}
              suffix={input.measurement}
              rules={inputValidationRule}
              onChange={(value) => {
                if (input.splitValue) {
                  const digits = value.split(/\D/).join("");
                  onChange(input.valueField as keyof ICreateReportInputRequired, +digits);
                } else onChange(input.valueField as keyof ICreateReportInputRequired, value);
              }}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" color="gray" onClick={onCancel}>
          Отменить
        </Button>
        <Button type="submit">Далее</Button>
      </ModalFooter>
    </StyledModalCreateReportFirstStep>
  );
};

export default ModalCreateReportFirstStep;
